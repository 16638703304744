import { Link } from 'gatsby';
// import styled from 'styled-components';
// import PropTypes from 'prop-types';
import React from 'react';

import logo from '../images/logo.svg';
import check from '../images/check.png';

// import navbarStyles from './navbar.module.scss';

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: '',
    };
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            });
      }
    );
  };

  check = () => {
    if (this.state.navBarActiveClass === 'is-active') {
      return (
        <div>
          <Link className="navbar-item" to="/termites">
            TERMITES
          </Link>
          <Link className="navbar-item" to="/videos">
            TERMITE VIDEOS
          </Link>
        </div>
      );
    } else {
      return (
        <div className="navbar-item has-dropdown is-hoverable">
          <Link className="navbar-item" to="/termites">
            TERMITES
          </Link>
          <div className="navbar-dropdown">
            <Link className="navbar-item" to="/termites">
              TERMITES
            </Link>
            <Link className="navbar-item" to="/videos">
              TERMITE VIDEOS
            </Link>
          </div>
        </div>
      );
    }
  };

  render() {
    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container" style={{ height: '0' }}>
          <div className="navbar-brand">
            <Link to="/" className="navbar-item" title="Logo">
              <img
                src={logo}
                alt="Tycecc Termite Control"
                style={{ width: '40px', margin: '0 auto' }}
              />
              <div
                className="companyName"
                style={{ fontSize: '1.3em', fontWeight: '600' }}
              >
                Tycecc Termite Control
              </div>
            </Link>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>

          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-end has-text-centered">
              <Link className="navbar-item" to="/company">
                LATERMITE.COM
              </Link>

              {this.check()}

              <Link className="navbar-item" to="/termite-inspection">
                INSPECTION
              </Link>
              <Link className="navbar-item" to="/termite-treatment">
                TREATMENTS
              </Link>
              <a
                className="navbar-item"
                href="http://tycecctermitepictures.blogspot.com/"
              >
                {/* <img src={check} alt="check-mark icon" /> */}
                COMPLETED JOBS
              </a>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default NavBar;
